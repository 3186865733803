<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<template>
  <div
    ref="element"
    slot="container-end"
    class="autoplay-progress absolute right-4 top-4 z-10 flex h-12 w-12 items-center justify-center stroke-[4px] font-bold text-white"
    data-testid="SliderAutoplayProgress"
  >
    <svg
      viewBox="0 0 48 48"
      class="absolute left-0 top-0 z-10 h-full w-full -rotate-90 fill-none stroke-white"
    >
      <circle cx="24" cy="24" r="20"></circle>
    </svg>
    <span></span>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const element = ref<HTMLElement | null>(null)

const initialize = () => {
  if (typeof document === 'undefined') {
    return null
  }

  const progressCircle = document.querySelector(
    '.autoplay-progress svg'
  ) as HTMLElement
  const progressContent = document.querySelector(
    '.autoplay-progress span'
  ) as HTMLElement

  const swiperEl = document.querySelector('swiper-container')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  swiperEl.addEventListener('autoplaytimeleft', (e: any) => {
    const [, time, progress] = e.detail
    progressCircle.style.setProperty('--progress', `${1 - progress}`)
    const seconds = Math.ceil(time / 1000)
    if (seconds <= 0) {
      return
    }
    progressContent.textContent = `${seconds}s`
  })
}

watch(element, (element) => {
  if (!element) {
    return
  }
  initialize()
})
</script>

<style lang="scss">
.autoplay-progress svg {
  --progress: 0;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
}
</style>
